import React, { useEffect, useState } from "react"
import Datepicker from "react-datepicker"
import countWorkingDays from "../../../utils/countWorkingDays"
import TeamSizeSelector from "../../../components/OnBoarding/TeamSizeSelector"
import DayPasses from "@components/SpaceProfile/InlineBooking/DayPasses"
import OnDemand from "@components/SpaceProfile/InlineBooking/OnDemand"
import OnDemandTypeSelector from "@components/OnBoarding/OnDemandTypeSelector"
import HourlyTimePicker from "../../OnBoarding/HourlyTimePicker"
import { hasHourlyOnDemand } from "@config"
import BackIcon from "@images/icons/back.svg"
import CloseIcon from "@images/icons/close.svg"
import { setValues } from "../../../stores/ProfileStore/actions"
import { resTypes } from "@config"

import * as styles from "./index.module.less"
import { useDispatch, useSelector } from "react-redux"
import addNewDateForDayPasses from "../../../utils/addNewDateForDayPasses"
import qs from "query-string"
import { navigate } from "gatsby"
import maxDateForCalendar from "../../../utils/maxDateForCalendar"

const MobileBooking = () => {
  const dispatch = useDispatch()
  const holidays = useSelector(state => state.profile.holidays)
  const startDate = useSelector(state => state.profile.startDate)
  const endDate = useSelector(state => state.profile.endDate)
  const minDate = useSelector(state => state.profile.minDate)
  const teamSize = useSelector(state => state.profile.teamSize)
  const location = useSelector(state => state.profile.location)
  const queryParams = useSelector(state => state.profile.queryParams)
  const reservation_type = useSelector(state => state.profile.reservation_type)
  const reservationStep = useSelector(state => state.profile.reservationStep)
  const isPerDayOnDemand = useSelector(state => state.profile.isPerDayOnDemand)
  const startTime = useSelector(state => state.profile.startTime)
  const endTime = useSelector(state => state.profile.endTime)
  const datesForDayPasses = useSelector(
    state => state.profile.datesForDayPasses
  )

  const setDates = dates => {
    if (dates.length !== 2) {
      if (!isPerDayOnDemand) {
        dispatch(
          setValues({
            startDate: dates,
            endDate: null,
            startTime: "",
            endTime: "",
          })
        )
        return
      }
      dispatch(
        setValues({ startDate: dates, endDate: null, reservationStep: 2 })
      )
      return
    }
    const [start, end] = dates
    if (start && !end) {
      dispatch(setValues({ startDate: start, endDate: null }))
    } else if (start && end) {
      dispatch(
        setValues({
          startDate: start,
          endDate: end,
          days: countWorkingDays(start, end, holidays[location.city] || []),
          reservationStep: 2,
        })
      )
    }
  }

  useEffect(() => {
    dispatch(
      setValues({
        fromReservationPage: false,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationStep, startDate])

  const [message, setMessage] = useState("")

  const setDayPassesDates = date => {
    if (!date) return

    dispatch(
      setValues({
        datesForDayPasses: addNewDateForDayPasses(datesForDayPasses, date),
      })
    )
  }

  useEffect(() => {
    // setting the message
    if (isPerDayOnDemand) {
      if (!endDate) {
        setMessage("Please select an end date")
        return
      } else if (!startDate || (startDate && endDate)) {
        setMessage("Please select a start date")
        return
      }
    } else if (!isPerDayOnDemand) {
      if (!startTime) {
        setMessage("Please select start time")
        return
      } else if (!endTime) {
        setMessage("Please select end time")
        return
      }
    }
    setMessage("")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, isPerDayOnDemand, startTime, endTime])

  const params =
    typeof window !== "undefined" ? qs.parse(window.location.search) : {}

  const changeType = type => {
    if (typeof window === "undefined") return
    const queryParams = qs.parse(window.location.search)
    delete queryParams.express_checkout
    delete queryParams.res_type
    queryParams.res_type = type
    const itemUnitForOnDemand = isPerDayOnDemand
      ? ["day", "days"]
      : ["hour", "hours"]
    dispatch(
      setValues({
        queryParams: queryParams,
        reservation_type: type,
        reservationType: resTypes.find(({ slug }) => slug === type),
        itemUnit:
          type === "day-passes" ? ["pass", "passes"] : itemUnitForOnDemand,
      })
    )
    if (type === "on-demand-offices" && isPerDayOnDemand) {
      dispatch(setValues({ startTime: "" }))
    }
    navigate(`${window.location.pathname}?${qs.stringify(queryParams)}`)
  }

  return (
    <div className={styles.mobile_booking}>
      <div className={styles.header}>
        {reservationStep >= 2 && (
          <button
            onClick={() => {
              dispatch(
                setValues({
                  fromReservationPage: false,
                  reservationStep: reservationStep - 1,
                })
              )
              // setStep(step - 1)
            }}
          >
            <img src={BackIcon} alt="Go Back" />
          </button>
        )}
        <button
          onClick={() =>
            dispatch(
              setValues({
                mobileOptionPicker: false,
                backFromCheckoutPage: false,
              })
            )
          }
        >
          <img src={CloseIcon} alt="Close" />
        </button>
      </div>
      <div
        className={`${styles.body_wrapper} ${
          reservationStep === 1 ? styles.date_picker : ""
        }`}
      >
        {reservationStep === 1 && (
          <>
            {resTypes.length > 1 && (
              <ul className={styles.res_type}>
                {resTypes.map(({ name, slug }) => (
                  <li key={slug}>
                    <button
                      onClick={() => {
                        changeType(slug)
                      }}
                      className={
                        params.res_type === slug ? styles.active_res_type : ""
                      }
                    >
                      {name}
                    </button>
                  </li>
                ))}
              </ul>
            )}
            <h4 className={styles.datepicker_heading}>
              {reservation_type === "on-demand-offices" ? (
                "Select start & end date"
              ) : (
                <>
                  Select drop-in date
                  <small>Multiple days can be selected</small>
                </>
              )}
            </h4>
            {queryParams.res_type !== "day-passes" && hasHourlyOnDemand && (
              <div className={styles.type_wrapper}>
                <OnDemandTypeSelector
                  type={isPerDayOnDemand ? "Daily" : "Hourly"}
                  setType={t => {
                    dispatch(
                      setValues({
                        isPerDayOnDemand: t === "Daily",
                        endDate: startDate,
                      })
                    )
                  }}
                />
              </div>
            )}
            {reservation_type === "on-demand-offices" ? (
              <Datepicker
                inline
                selectsRange={
                  queryParams.res_type === "on-demand-offices" &&
                  isPerDayOnDemand
                }
                maxDate={maxDateForCalendar(
                  process.env.GATSBY_CLIENT_UUID || ""
                )}
                minDate={minDate}
                selected={startDate}
                onChange={setDates}
                startDate={startDate}
                endDate={endDate}
                excludeDates={holidays[location.city] || []}
              />
            ) : (
              <Datepicker
                inline
                maxDate={maxDateForCalendar(
                  process.env.GATSBY_CLIENT_UUID || ""
                )}
                minDate={minDate}
                onChange={setDayPassesDates}
                highlightDates={datesForDayPasses}
                endDate={endDate}
                excludeDates={holidays[location.city] || []}
              />
            )}

            {/*{message && <p className={styles.message}>{message}</p>}*/}
            {!isPerDayOnDemand && startDate && (
              <div className={styles.time_picker_wrapper}>
                <HourlyTimePicker
                  alwaysBottom
                  startDate={startDate}
                  startTime={startTime}
                  endTime={endTime}
                  setStartTime={value =>
                    dispatch(setValues({ startTime: value }))
                  }
                  setEndTime={value => {
                    dispatch(setValues({ endTime: value, reservationStep: 2 }))
                  }}
                />
              </div>
            )}
            <div className={styles.next}>
              <button
                className={"button primary full"}
                disabled={!startDate}
                onClick={() => {
                  if (
                    !endDate &&
                    queryParams.res_type === "on-demand-offices"
                  ) {
                    dispatch(setValues({ endDate: startDate }))
                  }
                  // setStep(2)
                  dispatch(setValues({ reservationStep: 2 }))
                  // setStep(isSingleSpace ? 2 : 3)
                }}
              >
                Next
              </button>
            </div>
          </>
        )}
        {reservationStep === 2 && (
          <>
            <div style={{ position: "relative" }}>
              {queryParams.res_type === "on-demand-offices" ? (
                <TeamSizeSelector
                  teamSize={teamSize}
                  inline
                  setTeamSize={value => {
                    dispatch(setValues({ teamSize: value, reservationStep: 3 }))
                    // setStep(3)
                  }}
                />
              ) : (
                <>
                  <DayPasses />
                </>
              )}
            </div>
            {queryParams.res_type === "on-demand-offices" && teamSize > 0 && (
              <button
                className={"button primary full mt-4"}
                disabled={teamSize === 0}
                onClick={() => dispatch(setValues({ reservationStep: 3 }))}
              >
                Next
              </button>
            )}
          </>
        )}
        {reservationStep === 3 && queryParams.res_type === "on-demand-offices" && (
          <>
            {/* Fetch available spaces */}
            {/*<h4 className={styles.options_heading}>*/}
            {/*  {`${format(startDate, "MMM do")}${*/}
            {/*    !isSameDay(startDate, endDate)*/}
            {/*      ? ` - ${format(endDate, "MMM do")}`*/}
            {/*      : ""*/}
            {/*  }`}*/}
            {/*</h4>*/}
            <OnDemand />
          </>
        )}
      </div>
    </div>
  )
}

export default MobileBooking
